import styled from "styled-components";
import defaultImg from "../assets/images/logo.png";

const StyledHero = styled.div`
  color: white;
  font-size: 1.5rem;
  min-height: 60vh;
  background: linear-gradient(
      to right bottom,
      rgba(38, 38, 30, 0.9),
      rgba(38, 38, 30, 0.6)
    ),
    url(${(props) => (props.img ? props.img : defaultImg)}) center/cover
      no-repeat;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HomeHeroCarousel = styled.div`
  min-height: calc(100vh - 58px);
  background: linear-gradient(
      to right bottom,
      rgba(38, 38, 30, 0.9),
      rgba(38, 38, 30, 0.6)
    ),
    url(${(props) => (props.img ? props.img : defaultImg)}) center/cover
      no-repeat;
  width: 100%;
  padding: 4rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageGalleryCarousel = styled.div`
  min-height: calc(100vh - 18px);
  background: linear-gradient(
      to right bottom,
      rgba(38, 38, 30, 0.5),
      rgba(38, 38, 30, 0.2)
    ),
    url(${(props) => (props.img ? props.img : defaultImg)}) center/cover
      no-repeat;
  width: 100%;
  //height: 100%;
  //padding: 4rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledQuote = styled.div`
  background: linear-gradient(
      to right bottom,
      rgba(38, 38, 30, 0.9),
      rgba(38, 38, 30, 0.6)
    ),
    url(${(props) => (props.img ? props.img : defaultImg)}) center/cover
      no-repeat;
  display: grid;
  align-content: center;
  width: 100%;
  padding: 1rem;
  box-shadow: 2px 1px 7px 2px rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: justify;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  p {
    text-align: justify;
  }
`;

const StyledProgram = styled.div`
  background: linear-gradient(
      to right bottom,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.75)
    ),
    url(${(props) => (props.img ? props.img : defaultImg)}) center/cover
      no-repeat;
  height: auto;
  width: 100%;
  padding: 1rem;
  box-shadow: 2px 1px 7px 2px rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: justify;
  display: grid;
  align-content: center;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
  p {
    text-align: justify;
  }
  h4 {
    text-align: center;
  }
`;

const styles = {
  StyledHero,
  StyledQuote,
  StyledProgram,
};

export default styles;
