import React, { Component } from 'react';
import Hero from "../components/hero";
import Banner from "../components/banner";
import { Link } from "react-router-dom";

export default class Error extends Component {
  render() {
    //console.log()
    return (
      <>
        <Hero>
          <Banner title="404" subtitle="The requested page not found:">
            <p className="error-path-found">
              <span className="text-danger">{this.props.location.pathname}</span>
            </p>

            <Link to="/" className="btn btn-primary">
              return home
            </Link>
          </Banner>
        </Hero>
      </>
    )
  }
}
