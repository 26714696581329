import React from "react";

const developerDetails = {
  name: "Sibusiso Ncanywa",
  url: "https://www.linkedin.com/in/n-sibusiso",
};

export const DeveloperInfo = () => {
  return (
    <div className="footer-developer">
      <p>Designed and developed by </p>
      <a href={developerDetails.url} target="_blank" rel="noopener noreferrer">
        {developerDetails.name}
      </a>
    </div>
  );
};
