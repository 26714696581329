import React, { Component } from "react";
import Hero from "../components/hero";
import Banner from "../components/banner";
import Title from "../components/title";
import { Link } from "react-router-dom";
import { charityMessages } from "../staticData/charityData";

export default class Charity extends Component {
  render() {
    let { messages, contribution } = charityMessages;
    let { number, email, text } = contribution;
    return (
      <>
        <Hero hero="defaultPageHero">
          <Banner title="Our Charity Initiatives">
            <Link to="/" className="btn btn-primary">
              Return Home
            </Link>
          </Banner>
        </Hero>
        <section className="charity-List">
          <Title title="Little Ark Ministries Charity Messages" />

          <div className="charityList-center">
            {messages.map((item, index) => {
              return (
                <div key={`charity-index-${index}`} className="charity-message">
                  <p>{item}</p>
                </div>
              );
            })}
          </div>

          <div className="charity-contribution">
            <Title title="Little Ark Ministries Charity Events" />
            <p className="charity-message-contribution">
              {text}
              <div>
                <a href={"mailto:" + email}> {email}</a> or
                <a href={"tel:+" + number}> {number}</a>
              </div>
            </p>
          </div>
        </section>
      </>
    );
  }
}
