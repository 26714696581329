import LittleArkLogo from "../assets/images/logo.png";
import directorImg from "../assets/images/DirectorProfile/director.jpeg";

const whoAndWhy = [
  {
    title: "WHO ARE THE LITTLE ARK MINISTRIES?",
    value:
      "Little Ark Ministries is an organization which trains children from the age of 3 to 14 years of age to develop Christian principles and make them disciples of Christ in their generation (Matt. 28:16-20). We are not only addressing or reaching out other children from perspective of religion or spiritual point of view but also from ethical and moral point of view. We are Christian centered religious community dedicated and serving God, sharing the Gospel and helping others. Little Ark Ministries are children who take great joy in living according to Christ example of sacrifice and humility.",
  },
  {
    title: "WHY LITTLE ARK?",
    value:
      "The Education of Children: The careful, prudent, and religious education of children hath for the most part a very good influence upon the whole course of their lives.",
  },
];

const missionVisionMotto = [
  {
    title: "Our Mission",
    text: "The mission of Little Ark Ministries is to recognise that we live in end times, as a period between the first and the second advent of Christ. This recognition has created a sense of urgency to reach and gather every child into the boat, empower their mindset in pursuit to know that anytime we will be going to our heavenly home. In pursuit of this noble idea, Little Ark Ministries is determined to reach as many children as possible with a speed faster than that of light.",
  },
  {
    title: "Our Vision",
    text: "Let us give our children the mindset we wish we had when we were in their age.",
  },
  {
    title: "Our Motto",
    text: "If you don't have fun , you won't learn",
  },
];

const image = LittleArkLogo;

const authorDetails = {
  directorImg,
  director: "Founder",
  name: "Lukhanyo Peter",
  titles:
    "Pastor of the Seventh-day Adventist church, Author, Motivational speaker & Little Ark Ministries Director",
  summaryInfo: [
    "Is an experienced pastor & an educator professional. He is a Service Delivery Expert, a Brand influencer, an inspirational leader & a creative storyteller. And a voice of hope. He is a Coach for children and a friend to Teachers.",
    "Teaching and writing children's books is something that he enjoys most. God means so much to him and he just enjoys teaching kids about God and seeing them draw closer to Him. Just because he likes to do something doesn’t mean it’s easy. He studied and obtained BA Theology, Postgraduate Certificate in Education and BSS in Psychology with Rhodes University. Currently he is pursuing his BSS in Psychology with Honours with the University of Pretoria.",
  ],
  email: "peterl@littlearkministries.co.za",
};

export { whoAndWhy, missionVisionMotto, authorDetails, image };
