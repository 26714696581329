import React, { Component } from "react";
import Hero from "../components/hero";
import Banner from "../components/banner";
import Title from "../components/title";
import { Link } from "react-router-dom";
import { childrenPrograms } from "../staticData/childrenData";

export default class ChildrenPrograms extends Component {
  render() {
    const { message, booking, coveredTopics } = childrenPrograms;
    return (
      <>
        <Hero hero="defaultPageHero">
          <Banner title="Children Programs">
            <Link to="/" className="btn btn-primary">
              Return Home
            </Link>
          </Banner>
        </Hero>
        <section className="childrenPrograms-List">
          <div className="childrenPrograms-booking-message">
            <p>{message}</p>
            <Title title="Little Ark Children Programs Booking" />
            <p>
              {booking.text}
              <a href={"tel:+" + booking.number}>{booking.number}</a> or
              <a href={"mailto:" + booking.email}> {booking.email}</a>
            </p>
          </div>

          <div className="childrenProgramsList-center-title">
            <Title title={coveredTopics.title} />
            <div className="childrenProgramsList-center">
              {coveredTopics.topics.map((item, index) => {
                return (
                  <div
                    key={`children-program-topics-${index}`}
                    className="childrenPrograms-topic"
                  >
                    <h4> {item.topic} </h4>
                    <p> {item.text} </p>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </>
    );
  }
}
