import React, { Component } from "react";
import HomeQuotes from "../components/home/homeQuotes";
import FeaturedProducts from "../components/home/featuredProducts";
import OurPrograms from "../components/home/ourPrograms";
import HomeSocialMedia from "../components/home/homeSocialMedia";
import FeaturedVideos from "../components/home/featuredVideos";
import MissionVision from "../components/about/missionVision";
import HomeCarousel from "../components/home/homeCarousel";
import HomeWelcomeMessage from "../components/home/homeWelcomeMessage";

export default class Home extends Component {
  render() {
    return (
      <>
        <section className="home">
          <HomeCarousel />
          <div className="Home-within">
            <HomeWelcomeMessage />
            <MissionVision />
            <HomeQuotes />
            <FeaturedProducts />
            <OurPrograms />
            <HomeSocialMedia />
            <FeaturedVideos />
          </div>
        </section>
      </>
    );
  }
}
