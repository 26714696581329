import React, { Component } from "react";
import Hero from "../components/hero";
import Banner from "../components/banner";
import Title from "../components/title";
import { Link } from "react-router-dom";
import { bookingMessage } from "../staticData/childrenData";

export default class ChildrenParties extends Component {
  render() {
    const { message, booking } = bookingMessage;
    return (
      <>
        <Hero hero="defaultPageHero">
          <Banner title="Children Parties">
            <Link to="/" className="btn btn-primary">
              Return Home
            </Link>
          </Banner>
        </Hero>
        <section className="childrenParties-List">
          <div className="childrenPartiesList-center">
            <p className="childrenBooking-message">{message}</p>
            <Title title="Little Ark Ministries Events" />
            <p className="childrenBooking-booking">
              {booking.text}
              <a href={"tel:+" + booking.number}>{booking.number}</a> or
              <a href={"mailto:" + booking.email}> {booking.email}</a>
            </p>
          </div>
        </section>
      </>
    );
  }
}
