
export const charityMessages = {
  contribution: {
    text: "We at Little Ark Ministries we commit ourselves to partner with NPO's and schools where we will share our skills and resources in developing and equipping our communities. Should you wish to partner with us or donate towards this program kindly email us at:",
    number: "+27656224073",
    email: "charity@littlearkministries.co.za"
  },
  messages: [
    "“Happiness doesn't result from what we get, but from what we give” ~Ben Carson. Being a giver, you will find how amazing life is! Let us be givers😊.",
    "1 Peter 4:10, NIV: Each of you should use whatever gift you have received to serve others, as faithful stewards of God's grace in its various forms. But we can't do this alone. ",
    "One of the cornerstones of belonging to a religious community is taking part in acts of charity. Charity can take many forms, but most commonly it involves donating the most basic needs (money, clothing, food, shelter etc.) to those less fortunate than ourselves.",
    "Charity is much more than simply writing a cheque. It is an act of compassionate contact with another human being. Instead of remaining closed within ourselves, we open our hearts to our fellow human beings in need. This in turn lifts us up, emotionally and morally. The Bible alludes to this when it refers to charity as a “sanctified contribution” (Numbers 5:9).",
    "Christians are never judged by the confessions they make, or the creed they recite; it is always by their deeds. How much practical love have you manifested? How far have you responded to the cry for help from someone near you, someone who is destitute or disappointed, who needs an encouraging word or a helping hand or a generous check? This is the ultimate test.",
  ]
}
