import React from "react";
import Loading from "../loading";
import Product from "../product";
import Title from "../title";
import { withAppConsumer } from "../../context";

function FeatureProducts({ context }) {
  let { loading, featuredProducts, urlFor } = context;
  featuredProducts = featuredProducts.map((product) => {
    return <Product key={product._id} product={product} urlFor={urlFor} />;
  });
  const link = "http://store.littlearkministries.co.za/";
  //const link = "http://store.littlearkministries.co.za";
  return (
    <>
      <section className="featured-Products">
        <Title title="Featured Store Products" />
        <p className="featured-product-message">
          visit{" "}
          <a href={link} target="_blank" rel="noopener noreferrer">
            Little Ark Ministries Store
          </a>{" "}
          to see more products
        </p>
        <div className="featured-products-center">
          {loading ? <Loading /> : featuredProducts}
        </div>
      </section>
    </>
  );
}
export default withAppConsumer(FeatureProducts);
