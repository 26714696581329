import React, { Component } from "react";
import Title from "./title";
import {
  AddressInfo,
  EmailNumberReg,
  SocialMediaLinks,
  FooterCopyright,
} from "../staticData/brandData";
import { DeveloperInfo } from "../staticData/developerData";

export default class Footer extends Component {
  render() {
    return (
      <>
        <footer>
          <div className="footer-details">
            <div className="footer-address">
              <AddressInfo />
              <EmailNumberReg />
            </div>

            <div className="footer-socialMedia">
              <Title title="Follow Us" />
              <SocialMediaLinks />
            </div>
            {
              // include contact form later
            }
          </div>

          {
            // bottom of the footer
            // copyright
          }
          <FooterCopyright />

          {
            // developer link section
          }
          <DeveloperInfo />
        </footer>
      </>
    );
  }
}
