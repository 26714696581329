import React, { Component } from "react";
import Hero from "../components/hero";
import Banner from "../components/banner";
import { Link } from "react-router-dom";
import ParentingLetter from "../components/parenting/parentingLetter";
import ParentingSeminars from "../components/parenting/parentingSeminars";
import ParentingQuotes from "../components/parenting/parentingQuotes";

export default class Parenting extends Component {
  render() {
    return (
      <>
        <Hero hero="defaultPageHero">
          <Banner title="Parenting">
            <Link to="/" className="btn btn-primary">
              Return Home
            </Link>
          </Banner>
        </Hero>
        <section className="parenting">
          <ParentingLetter />
          <ParentingSeminars />
          <ParentingQuotes />
        </section>
      </>
    );
  }
}
