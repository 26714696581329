import React from "react";
import Title from "../title";
import { Carousel } from "react-bootstrap";
import { testimonials } from "../../staticData/trainingData";

export default function TrainingTestimonials() {
  return (
    <>
      <section className="trainingTest-List">
        <Title title="Testimonials" />
        <Carousel>
          {testimonials.map((item, index) => {
            return (
              <Carousel.Item key={`carousel-testimonial-index-${index}`}>
                <div className="testimonial">
                  <h4>{item.name}</h4>
                  <p>{item.message}</p>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </section>
    </>
  );
}
