import React from "react";
import { imageGallery } from "../../staticData/imageData";
import { ImageGalleryCarousel } from "../styledHero";
import { Carousel } from "react-bootstrap";
import LittleArkLogo from "../../assets/images/logo.png";

export default function ImageGallery() {
  return (
    <div className="images-galleryList-center">
      <Carousel>
        {imageGallery.map((item, index) => {
          return (
            <Carousel.Item key={`carousel-testimonial-index-${index}`}>
              <ImageGalleryCarousel img={item.url || LittleArkLogo}>
                <h4>{item.alt}</h4>
              </ImageGalleryCarousel>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}
