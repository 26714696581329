import React from "react";
import Title from "../title";
import { withAppConsumer } from "../../context";

function FeaturedVideos({ context }) {
  let { featuredVideos } = context;

  return (
    <>
      <div className="homeFeaturedVideos-List">
        <Title title="Our Youtube Page" />
        <div className="homeFeaturedVideos">
          {featuredVideos.map((item, index) => {
            const { embedId } = item;
            return (
              <div
                key={`youtube-index-${index}`}
                className="galleryList-video-item"
              >
                <iframe
                  width="853"
                  height="480"
                  src={`https://www.youtube.com/embed/${embedId}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
export default withAppConsumer(FeaturedVideos);
