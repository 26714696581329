import React from "react";
import Title from "./title";
import directorImg from "../assets/images/DirectorProfile/founder.jpeg";
import { authorDetails } from "../staticData/generalInfo";

export default function AuthorProfile() {
  const { name, director, summaryInfo, email } = authorDetails;
  return (
    <>
      <article className="author-Profile">
        <Title title={director} />
        <section className="author-Profile-titles-img">
          <img src={directorImg} alt="PS.Peter" />
          <div>
            <h2>{name}</h2>
          </div>
        </section>
        <div className="author-Profile-summary">
          {summaryInfo.map((message, index) => {
            return <p key={`director-message${index}`}>{message}</p>;
          })}
        </div>
        <p>
          To get hold of the director email:
          <a href={`mailto:${email}`}> {email}</a>
        </p>
      </article>
    </>
  );
}
