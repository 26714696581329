import React from "react";
import { Carousel } from "react-bootstrap";
import { HomeHeroCarousel } from "../styledHero";
import defaultBcg from "../../assets/images/logo.png";
import Banner from "../banner";
import { homeQuotes } from "../../staticData/homeData";

function HomeCarousel() {
  let quotes = homeQuotes.map((item, index) => {
    return (
      <Carousel.Item key={`home-quotes-carouel-index-${index}`}>
        <HomeHeroCarousel img={item.image || defaultBcg}>
          <Banner title="Little Ark Ministries">{item.text}</Banner>
        </HomeHeroCarousel>
      </Carousel.Item>
    );
  });

  return (
    <>
      <Carousel>{quotes}</Carousel>
    </>
  );
}
export default HomeCarousel;
