import React, { Component } from "react";
import Hero from "../components/hero";
import Banner from "../components/banner";
import AuthorProfile from "../components/authorProfile";
import AboutUs from "../components/about/aboutUs";
import MissionVision from "../components/about/missionVision";
import { Link } from "react-router-dom";
import Title from "../components/title";

export default class About extends Component {
  render() {
    return (
      <>
        <Hero hero="defaultPageHero">
          <Banner title="About Us">
            <Link to="/" className="btn btn-primary">
              Return Home
            </Link>
          </Banner>
        </Hero>
        <section className="about">
          <AboutUs />
          <Title title="Our Mission, Vision and Motto" />
          <MissionVision />
        </section>
        <AuthorProfile />
      </>
    );
  }
}
