import React from "react";
import { socialMediaHandles } from "./homeData";
import {
  FaFacebook,
  FaYoutube,
  FaInstagram,
  //FaWhatsapp,
  FaMapMarked,
  FaPhone,
} from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";

let { facebook, instagram, youtube } = socialMediaHandles;
// create a date constant
const myTime = new Date();

const littleArkInfo = {
  brandName: "Little Ark Ministries",
  currentYear: myTime.getFullYear(),
  regNo: "Reg no. 2020/003181/07",
  address:
    "8 Robinson street, Makhanda(Grahamstown) 6139, Eastern Cape, South Africa",
  tel: "+27656224073",
  mail: "info@littlearkministries.co.za",
  fb: facebook.link,
  insta: instagram.link,
  twt: "https://www.twitter.com/",
  youtube: youtube.link,
};

export const AddressInfo = () => {
  return (
    <address id="contactUs" className="footer-address">
      <span className="mapIcon">
        <FaMapMarked />
      </span>
      {littleArkInfo.address}
    </address>
  );
};

let contactDetailsList = [
  {
    icon: <AiOutlineMail />,
    link: "mailto:" + littleArkInfo.mail,
    name: littleArkInfo.mail,
  },
  {
    icon: <FaPhone />,
    link: "tel:" + littleArkInfo.tel,
    name: littleArkInfo.tel,
  },
];
export const EmailNumberReg = () => {
  return (
    <>
      {contactDetailsList.map((item, index) => {
        return (
          <a
            key={`contact-list-item-${index}`}
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={"icon-size " + item.name}>{item.icon}</span>
            <span className="link">{item.name}</span>
          </a>
        );
      })}
      <p className="footer-registration">{littleArkInfo.regNo}</p>
    </>
  );
};

let SocialLinks = [
  {
    icon: <FaFacebook />,
    link: littleArkInfo.fb,
    name: facebook.handle,
    class: "facebook",
  },
  {
    icon: <FaInstagram />,
    link: littleArkInfo.insta,
    name: instagram.handle,
    class: "instagram",
  },
  {
    icon: <FaYoutube />,
    link: littleArkInfo.youtube,
    name: youtube.handle,
    class: "youtube",
  },
];

export const SocialMediaLinks = () => {
  return (
    <div className="socials-icons">
      {SocialLinks.map((item, index) => {
        return (
          <a
            key={`social-links-item-${index}`}
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={"icon-size " + item.class}>{item.icon}</span>
          </a>
        );
      })}
    </div>
  );
};

export const FooterCopyright = () => {
  return (
    <div>
      <span className="footer-copyright">{littleArkInfo.brandName}</span> &copy;
      {littleArkInfo.currentYear}
    </div>
  );
};
