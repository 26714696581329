
export const bookingMessage = {
  message: "Little Ark Ministries believe in celebrating every WIN no matter how small it is. Children want the very same things adults want, to be loved and laugh. We organize and do ALL parties, from birthdays to celebrations.",
  booking: {
    text: "For more info and bookings: ",
    number: "+27656224073",
    email: "party@littlearkministries.co.za",
  }
}

export const childrenPrograms = {
  message: "Having examined ALL the approaches to teaching children the Bible, we have picked the most memorable and fun exercises. It is often said that “Idle hands are the Devil's workshop”, and that can certainly be true with children. We're children, we are the future!!",
  booking: {
    text: "To see or get more info about the programs we offer contact: ",
    number: "+27656224073",
    email: "info@littlearkministries.co.za",
  },
  coveredTopics: {
    title: "Some of the topics covered in the children program",
    topics: [
      {
        topic: "Alphabets of the Bible",
        text: "In each letter we give a brief background or summary about that character. For an example - A is for Abraham: who left his home and went to a land God promised him. He fathered his son Isaac when he was 90years old and he is known as the father of ALL nations (Genesis 12 - 25)."
      },
      {
        topic: "Mnemonics of the Bible",
        text: "We give a child a sentence where the letter in that sentence will remind a child of something. For an example - God Expect Love Not Discord: God- Genesis, Expect- Exodus, Love- Leviticus, Not- Numbers, Discord- Deuteronomy."
      },
      {
        topic: "SOAP",
        text: "When a child apply SOAP in her BIBLE study, she won't only the verse but she knows something about the verse. S- Scripture, O- Observation, A- Application, P- Prayer."
      },
      {
        topic: "How to pray?",
        text: "Children, they don't know what to say to God when asked to pray and how to say it. At Little Ark Ministries we teach children how to pray. We use various object lessons like; the pumpkin prayer and Oreo prayer."
      },
      {
        topic: "How to read or study the Bible?",
        text: "The Bible at times is a big and scary book where even mature students/parents don't know where to start. We, Little Ark came up with exciting methods to make the reading of the Bible fun and exciting while learning. We use methods and programs like: * The Alphabets of the Bible * The Mnemonics of the Bible * Who said That? Our books are designed in a manner that children navigate the Bible while they're looking for answers when completing the worksheets. Any many more!"
      },
      {
        topic: "Team Building",
        text: "We use various games to accomplish this goal."
      }
    ]
  }
}
