// Video Links
const embedId0 = "K3ucd7s7T7Y";
const embedId1 = "6CYykzcWGvY";
const embedId2 = "yxJvplRHZV8";
const embedId3 = "ZiV2KpA3xqI";
const embedId4 = "ejEeeqL-JYM";
const embedId5 = "UxcG76c8zoo";
const embedId6 = "WurGJuH3aY8";
const embedId7 = "WgTJxhvEKKo";
const embedId8 = "wYj8biHfTTU";
const embedId9 = "cuDlWtuwv6U";

export const videoGallery = [
  {
    embedId: embedId0,
    featured: true,
  },
  {
    embedId: embedId1,
    featured: true,
  },
  {
    embedId: embedId2,
    featured: true,
  },
  {
    embedId: embedId3,
    featured: false,
  },
  {
    embedId: embedId4,
    featured: false,
  },
  {
    embedId: embedId5,
    featured: false,
  },
  {
    embedId: embedId6,
    featured: false,
  },
  {
    embedId: embedId7,
    featured: false,
  },
  {
    embedId: embedId8,
    featured: false,
  },
  {
    embedId: embedId9,
    featured: false,
  },
];
