import React from "react";
import defaultBcg from "../../assets/images/logo.png";
import Styles from "../styledHero";
import Title from "../title";
import { homeQuotes } from "../../staticData/homeData";

function HomeQuotes() {
  let { StyledQuote } = Styles;
  return (
    <>
      <div className="homeQuotes-List">
        <Title title="The Mandate we aim to achieve" />
        <div className="homeQuotesList-center">
          {homeQuotes.map((item, index) => {
            return (
              <StyledQuote
                key={`home-quotes-index-${index}`}
                img={item.image || defaultBcg}
              >
                <p> {item.text} </p>
              </StyledQuote>
            );
          })}
        </div>
      </div>
    </>
  );
}
export default HomeQuotes;
