import React from "react";
import Title from "../title";
import { topicsCovererd } from "../../staticData/trainingData";

export default function TrainingTopics() {
  let { title, topics } = topicsCovererd;

  return (
    <>
      <section className="trainingTopics-List">
        <Title title={title} />
        <div className="trainingTopicsList-center">
          {topics.map((item, index) => {
            return (
              <div key={`training-topic-index-${index}`} className="topic">
                <h4>{item.title}</h4>
                <p>{item.text}</p>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}
