import authorPicture from "../assets/images/DirectorProfile/director.jpeg";
import blankPaper from "../assets/images/blankletter-old.jpg";

const letterToParents = {
  author: "Lukhanyo Peter, Director (Little Ark Ministries)",
  image: authorPicture,
  blankPaper,
  letter: {
    letterHead:
      "Discover How to Overcome Christian Parenting’s Biggest Obstacles, And Draw Your Kids Closer to God!",
    subTitle: "Attention: Busy Christian Parents",
    intro:
      "Dear Parent: First off, I know how busy you are, so I’m going to keep this brief ... Now let me quickly draw your attention to a problem that just about all of us Christian parents worry about at one time or another…",
    sections: [
      {
        text: "Unfortunately, in today’s busy world, God often has to compete for time with work, school, practice, shopping, chores and much, much more. At the same time, all of our “busy-ness” can often drain the fun (and the meaning) from everyday life. So here’s the thing. If you’re having a hard time instilling in your children a deep love and appreciation for God & life-long morals then please don’t worry.",
      },
      {
        text: "Being a devoted Christian and a Parent does not automatically mean you are comfortable discussing your Faith with your kids. And it certainly doesn’t mean you know how to teach your children to strengthen THEIR relationship with God. And it certainly doesn’t mean you know how to effortlessly confront every-day parenting dilemmas with Biblical wisdom.",
      },
      {
        text: "And trying to raise up a Christian child with strong character and morals in today’s world is even HARDER. But what if I told you there was a simple, fun way to ensure your children get to know God better while also gaining a better understanding of Christian behavior and the benefits of living a virtuous life?",
      },
      {
        text: "If so, please keep reading. As a Father, Husband, Brother& Friend… the Bible is my guide. I read the Bible every single day. But the Bible is long, and the language can be complex (even for me). Over and over again I’ve found myself confronted with a parenting struggle and I’ve tried to turn to the Bible for help. So I put my passion for organizing to good use, and categorized all my notes into 40 (lessons) sections (including Moses, Joshua, Exodus stories and the Sanctuary and its services) filled with my favorite ways to share Biblical wisdom with children. And today, through Little Ark Ministries I’m sharing my collection of Christian work-books with you!",
      },
      {
        text: "This parenting tool wasn't designed to be a burden. It was designed to ease burdens. There's no “hard work” necessary. No serious time commitments and certainly no impact on the family budget. So if you’re not used to being chatty with your children or if a certain subject seems a bit tough to tackle, you don’t have to worry … I’m giving you a suggestion as to exactly what you can say. Little Ark is not really little as the name suggest but it is the safest place to be for you and your children whilst we are waiting for that GLORIOUS DAY!",
      },
    ],
    conclusion:
      "My hope is that through these work-books your children will draw closer to each other, closer to you as a parent, and closer to God.",
  },
};
const parentingSeminars = {
  message:
    "When our children travel the world in fear of their lives, crossing seas, mountains & deserts to find safety, something must be wrong, we need to fix those wrongs, we must be that safety! \n#protectchildren #parenting",
  booking: {
    text: "To book a Parenting seminar please email:",
    email: "training@littlearkministries.co.za",
  },
  topicsCovered: {
    title: "Some of the topics covered in our parenting seminars",
    topics: [
      "5 things you can learn from your child.",
      "Say yes in everything.",
      "Understanding suicide in children.",
      "and many more...",
    ],
  },
};

const parentingQuotes = [
  {
    author: "Unknown",
    text: "“A good EDUCATION begins at HOME, you cannot blame a school for not nurturing values in your child that you have not instilled.” ",
  },
  {
    author: "Unknown",
    text: "“Believe in your child. Believe they are capable of anything—and tell them that.”",
  },
  {
    author: "Brooke Hampton",
    text: "“Speak to your children as if they are the wisest, kindest, most beautiful and magical humans on earth, for what they believe is what they will become.”",
  },
  {
    author: "Michio Kaku",
    text: "“All kids are born geniuses, but are crushed by society”",
  },
];

export { letterToParents, parentingSeminars, parentingQuotes };
