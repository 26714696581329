import React from "react";
import { Link } from "react-router-dom";
import Title from "../title";
import defaultBcg from "../../assets/images/logo.png";
import Styles from "../styledHero";
import { withAppConsumer } from "../../context";

function OurPrograms({ context }) {
  let { ourPrograms } = context;
  let { StyledProgram } = Styles;
  return (
    <>
      <section className="home-ourPrograms">
        <Title title="Our Programs" />
        <div className="home-ourPrograms-center">
          {
            ourPrograms.map((item, index) => {
              return (
                <StyledProgram key={`ourprograms-item-index-${index}`} img={item.image || defaultBcg}>
                  <h4>{item.title}</h4>
                  <p> {item.text} </p>
                  <Link to={"/" + item.link} className="btn btn-primary text-center w-100">
                    {item.title}
                  </Link>
                </StyledProgram>
              )
            })
          }
        </div>
      </section>
    </>
  )
}
export default withAppConsumer(OurPrograms);
