import React from "react";
import quote1 from "../assets/images/quotes/hands.jpg";
import quote2 from "../assets/images/quotes/lion.jpg";
import quote3 from "../assets/images/quotes/ostrich-family.jpg";

import prog1 from "../assets/images/logo.png";
import training from "../assets/images/ourPrograms/training.jpg";
import party from "../assets/images/ourPrograms/party.jpg";
import program from "../assets/images/ourPrograms/chPrograms.jpg";
//import {SocialLinks}  from "./brandData";

export const socialMediaHandles = {
  facebook: {
    handle: "Little Ark Ministries",
    link: "https://www.facebook.com/Little-Ark-Ministries-103844167762633",
  },
  instagram: {
    handle: "Little Ark Ministries",
    link: "https://www.instagram.com/littlearkministries/",
  },
  youtube: {
    handle: "Little Ark Ministries",
    link: "https://www.youtube.com/channel/UCdwv8-xGSWH3pmWDYrBR7jA",
  },
};

export const homeQuotes = [
  {
    image: quote1,
    text: "We are creating an Individual Support Plan (ISP) for each child which highlights barriers and interventions. This ISP is revised every semester and discussed with parents.",
  },
  {
    image: quote2,
    text: "Little Ark Ministries Grooms Leaders!",
  },
  {
    image: quote3,
    text: "Little Ark steps back, empower children and support them from behind. Little Ark walk fast to follow children, for she is their leader!",
  },
];

export const ourPrograms = [
  {
    title: "Children Programs",
    text: "We provide character-based leadership and educational services that help children to care deeply, live ethically, and act courageously.",
    link: "children-programs",
    image: program,
  },
  {
    title: "Children Parties",
    text: "We also enjoy having fun with children and ensuring that they are happy. Remember, if you don't have fun you won't learn.",
    link: "children-parties",
    image: party,
  },
  {
    title: "Training",
    text: "Little Ark believes in the continual training and mentoring. We keep up with new developments and strategies in education to remain relevant.",
    link: "training",
    image: training,
  },
  {
    title: "Parenting",
    text: "As parents, don’t we all just want to raise “good kids”? We want them to be thankful, polite, joyful, and well behaved. We want them to grow up and become adults with good character.",
    link: "parenting",
    image: training,
  },
  {
    title: "Charity",
    text: "Little ark also focuses on doing charity work.",
    link: "charity",
    image: prog1,
  },
];

const welcomeMessage0 = () => {
  const message = ["if you do not have ", " you will not "];
  const keywords = ["fun", "learn"];
  return (
    <div className="welcome-message-container-m1">
      <p>{message[0]}</p>
      <p className="welcomeMessage-keyword">{keywords[0]}</p>
      <p>{message[1]}</p>
      <p className="welcomeMessage-keyword">{keywords[1]}</p>
    </div>
  );
};

const welcomeMessage1 = () => {
  const message = [
    "To teach children;",
    " is the Greatest legacy we can leave!",
  ];
  const keyword = "Love, Kindness, Compassion & Non Judgement";
  return (
    <div className="welcome-message-container-m0">
      <p>{message[0]}</p>
      <p className="welcomeMessage-keyword">{keyword}</p>
      <p>{message[1]}</p>
    </div>
  );
};

const randomBooleanGen = () => {
  const ar = [true, false];
  let index = Math.round(Math.random());
  return ar[index];
  //return Math.random() >= 0.5;
};

export const RandomWelcomeMessage = () => {
  /**
   * get random pre-saved messages
   * The message object must have the following
   * message ["if you don't have", "you won't"]
   * keywords ["fun", "learn"]
   *  >> keywords must be bold, italic
   *
   * To teach children; Love, Kindness,
   * Compassion & Non Judgement is the Greatest legacy we can leave!
   *
   */
  let random = randomBooleanGen();
  switch (random) {
    case true:
      return welcomeMessage1();
    default:
      return welcomeMessage0();
  }
};
