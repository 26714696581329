import React, { Component } from "react";
import { FaAlignRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import BrandLogo from "../assets/images/logo.png";
import { NavLinks, ProgramLinks } from "../staticData/navbarData";

export default class Navbar extends Component {
  state = {
    isOpen: false,
  };
  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    return (
      <header>
        <nav className="navbar navbar-expand-md">
          <div>
            <Link to="/" className="navbar-brand">
              <img src={BrandLogo} alt="Little Ark Ministries" />
            </Link>

            <button
              type="button"
              className="navbar-toggler nav-icon btn"
              onClick={this.handleToggle}
            >
              <FaAlignRight />
            </button>
          </div>

          <div className="container-fluid">
            <div
              className={
                this.state.isOpen
                  ? "navbar-collapse text-center"
                  : "collapse navbar-collapse text-center"
              }
            >
              <ul className="navbar-nav ml-auto">
                <NavLinks />

                <li className="nav-item nav-programs">
                  {this.state.isOpen ? (
                    <span></span>
                  ) : (
                    <span className="nav-link programs-label">Programs</span>
                  )}
                  <ul
                    className={
                      this.state.isOpen
                        ? "text-center show-programs-list"
                        : "text-center nav-programs-list"
                    }
                  >
                    <ProgramLinks isOpen={this.state.isOpen} />
                  </ul>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="http://store.littlearkministries.co.za/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Store
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
