import React from "react";
import "./app.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Switch } from "react-router-dom";

// import all the pages
import Home from "./pages/home";
import AboutUs from "./pages/about";
import ChildrenPrograms from "./pages/childrenPrograms";
import ChildrenParties from "./pages/childrenParties";
import Training from "./pages/training";
import Parenting from "./pages/parenting";
import Charity from "./pages/charity";
import Gallery from "./pages/gallery";

// import all the components
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Error from "./pages/error";

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about/" component={AboutUs} />
        <Route exact path="/children-programs/" component={ChildrenPrograms} />
        <Route exact path="/children-parties/" component={ChildrenParties} />
        <Route exact path="/training/" component={Training} />
        <Route exact path="/parenting/" component={Parenting} />
        <Route exact path="/charity/" component={Charity} />
        <Route exact path="/gallery/" component={Gallery} />
        <Route component={Error} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
