import image1 from "../assets/images/imageGallary/1.jpg";
import image2 from "../assets/images/imageGallary/2.jpg";
import image3 from "../assets/images/imageGallary/3.jpg";
import image4 from "../assets/images/imageGallary/4.jpg";
import image5 from "../assets/images/imageGallary/5.jpg";
import image6 from "../assets/images/imageGallary/6.jpg";
import image7 from "../assets/images/imageGallary/7.jpg";
import image8 from "../assets/images/imageGallary/8.jpg";
import image9 from "../assets/images/imageGallary/9.jpg";
import image10 from "../assets/images/imageGallary/10.jpg";

import g1 from "../assets/images/imageGallary/g1.jpeg";
import g2 from "../assets/images/imageGallary/g2.jpeg";
import g3 from "../assets/images/imageGallary/g3.jpeg";
import g4 from "../assets/images/imageGallary/g4.jpeg";
import g5 from "../assets/images/imageGallary/g5.jpeg";
import g6 from "../assets/images/imageGallary/g6.jpeg";
import g7 from "../assets/images/imageGallary/g7.jpeg";
import g8 from "../assets/images/imageGallary/g8.jpeg";
import g9 from "../assets/images/imageGallary/g9.jpeg";
import g10 from "../assets/images/imageGallary/g10.jpeg";
import g11 from "../assets/images/imageGallary/g11.jpeg";
import g12 from "../assets/images/imageGallary/g12.jpeg";
import g13 from "../assets/images/imageGallary/g13.jpeg";
import g14 from "../assets/images/imageGallary/g14.jpeg";
import g15 from "../assets/images/imageGallary/g15.jpeg";

export const imageGallery = [
  {
    alt: "",
    url: g1,
  },
  {
    alt: "",
    url: g2,
  },
  {
    alt: "",
    url: g3,
  },
  {
    alt: "",
    url: g4,
  },
  {
    alt: "",
    url: g5,
  },
  {
    alt: "",
    url: g6,
  },
  {
    alt: "",
    url: g7,
  },
  {
    alt: "",
    url: g8,
  },
  {
    alt: "",
    url: g9,
  },
  {
    alt: "",
    url: g10,
  },
  {
    alt: "",
    url: g11,
  },
  {
    alt: "",
    url: g12,
  },
  {
    alt: "",
    url: g13,
  },
  {
    alt: "",
    url: g14,
  },
  {
    alt: "",
    url: g15,
  },
  {
    alt: "",
    url: image1,
  },
  {
    alt: "",
    url: image2,
  },
  {
    alt: "",
    url: image3,
  },
  {
    alt: "",
    url: image4,
  },
  {
    alt: "",
    url: image5,
  },
  {
    alt: "",
    url: image6,
  },
  {
    alt: "",
    url: image7,
  },
  {
    alt: "",
    url: image8,
  },
  {
    alt: "",
    url: image9,
  },
  {
    alt: "",
    url: image10,
  },
];
