import React, { Component } from "react";
import Hero from "../components/hero";
import Banner from "../components/banner";
import { Link } from "react-router-dom";
import TrainingFruits from "../components/training/trainingFruits";
import TrainingQuestionsAnswers from "../components/training/trainingQuestionsAnswers";
import TrainingTopics from "../components/training/trainingTopics";
import TrainingTestimonials from "../components/training/trainingTestimonials";

export default class Training extends Component {
  render() {
    return (
      <>
        <Hero hero="defaultPageHero">
          <Banner title="Training">
            <Link to="/" className="btn btn-primary">
              Return Home
            </Link>
          </Banner>
        </Hero>
        <section className="training">
          <TrainingFruits />
          <TrainingQuestionsAnswers />
          <TrainingTopics />
          <TrainingTestimonials />
        </section>
      </>
    );
  }
}
