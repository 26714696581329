import React from "react";
import Title from "../title";
import { socialMediaHandles } from "../../staticData/homeData";

function HomeSocialMedia() {
  let { facebook, instagram, youtube } = socialMediaHandles;
  return (
    <>
      <div className="homeSocial-List">
        <Title title="Join Our Social Media family" />
        <div className="homeSocialList-center">
          <a
            className="social-link facebook"
            href={`http://${facebook.link}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Title title="Facebook" />
            {facebook.handle}
          </a>

          <a
            className="social-link instagram"
            href={`http://${instagram.link}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Title title="Intagram" />
            {instagram.handle}
          </a>

          <a
            className="social-link youtube"
            href={`http://${youtube.link}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Title title="YouTube" />
            {youtube.handle}
          </a>
        </div>
      </div>
    </>
  );
}
export default HomeSocialMedia;
