import React from "react";
import { videoGallery } from "../../staticData/youtubeData";
import Title from "../title";

function YoutubeGallery() {
  return (
    <section className="youtube-galleryList-center">
      <Title title="Youtube Videos" />
      <div className="video-gallery-container">
        {videoGallery.map((item, index) => {
          const { embedId } = item;
          return (
            <div
              key={`youtube-index-${index}`}
              className="galleryList-video-item"
            >
              <iframe
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/${embedId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default YoutubeGallery;
