import React from "react";
import { whoAndWhy, image } from "../../staticData/generalInfo";

function AboutUs() {
  return (
    <>
      <div className="aboutUs-info">
        <img src={image} alt="little Ark Logo" />
        <div className="about-who-why">
          {whoAndWhy.map((item, index) => {
            return (
              <div key={`who-why-index-${index}`}>
                <h3> {item.title} </h3>
                <p> {item.value} </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
export default AboutUs;
