import React from "react";
import Title from "../title";
import { letterToParents } from "../../staticData/parentingData";

export default function ParentingLetter() {
  let { author, image, blankPaper, letter } = letterToParents;
  return (
    <>
      <section className="parenting-letter">
        <img className="bg-blank-page" src={blankPaper} alt="letterBg" />
        <div className="parenting-container">
          <Title title={letter.letterHead} />
          <h3>{letter.subTitle}</h3>
          <div className="parenting-letter-body">
            <div className="img-name">
              <img src={image} alt="Author" />
              <h3>{author}</h3>
            </div>
            <div className="letter-body">
              <p>{letter.intro}</p>
              {letter.sections.map((item, index) => {
                return (
                  <div key={`letter-section-index-${index}`}>
                    <p>{item.text}</p>
                  </div>
                );
              })}
              <p>{letter.conclusion}</p>
              <p>kind regards</p>
              <p>{author}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
