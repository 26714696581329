import React, { Component } from "react";
import { videoGallery } from "./staticData/youtubeData";
import { ourPrograms } from "./staticData/homeData";
import { bookingMessage, childrenPrograms } from "./staticData/childrenData";
import { client, urlFor } from "./lib/clientSanity";

//import { items } from "./staticData/data";
//import Client from "./contentful";

const AppContext = React.createContext();

// Provider
// allows the components in the compenents tree to access it
// have to wrap

// consumer is used to access the info in the provider

/**
 * Central data storage for Little Ark Ministries
 * Allows data to be accessed by components in the components tree
 * @class AppProvider
 * @extends {Component}
 */
class AppProvider extends Component {
  state = {
    featuredProducts: [],
    featuredVideos: [],
    loading: true,
  };

  /**
   * Gets data from a CMS or Local storage
   * @memberof AppProvider
   */
  getData = async () => {
    try {
      //let response = await Client.getEntries({
      //  content_type: "travelCollectiveData"
      //});

      // product query
      const queryProducts = '*[_type == "product"]';
      // fetch products
      let responseProducts = await client.fetch(queryProducts);

      // format the product data to make it usable by the app
      let products = this.formatData(responseProducts);

      // find products with the feature property set to true
      let featuredProducts = products.filter(
        (product) => product.featured === true
      );

      // find videos with the feature property set to true
      let featuredVideos = videoGallery.filter(
        (item) => item.featured === true
      );

      this.setState({
        featuredProducts,
        featuredVideos,
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Used to access external data
   * @memberof AppProvider
   */
  componentDidMount() {
    this.getData();
  }

  /**
   * Formats data from items
   * @param {*} items
   */
  formatData(items) {
    //const { image, name, description,slug,keywords,featured, price } = product;
    let tempItems = items.map((item) => {
      let inCart = false;
      let count = 0;
      let total = 0;
      //let product = { ...item.fields,inCart };
      //console.log(item.slug.current);
      let product = { ...item, inCart, count, total };
      return product;
    });
    return tempItems;
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          videoGallery: videoGallery,
          ourPrograms: ourPrograms,
          bookingMessage: bookingMessage,
          childrenPrograms: childrenPrograms,
          urlFor,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

const AppConsumer = AppContext.Consumer;

/**
 * Allows access to data
 *
 * @export withAppConsumer
 * @param {*} Component
 * @returns {*}
 */
export function withAppConsumer(Component) {
  return function ConsumerWrapper(props) {
    return (
      <AppConsumer>
        {(value) => <Component {...props} context={value} />}
      </AppConsumer>
    );
  };
}

export { AppProvider, AppConsumer, AppContext };
