/**
 * Document contains data for the training page
 *
 */

const trainingFruits = {
  text: "Fruits our training",
  benefits: [
    {
      title: "Purpose of the Training",
      text: [
        "Is to introduce you to the key aspect of teaching children & the particular characteristics of children. Henceforth, the purpose is to develop beginner teacher to become creative & confident.",
      ],
    },
    {
      title: "Objectives",
      text: [
        "To demonstrate insight into the characteristics of children.",
        "To discover how young children, learn.",
        "To consider the value of play in children.",
        "To display a typical day of worship for children.",
      ],
    },
    {
      title: "Aim",
      text: [
        "In a due care to inform and instruct them in the whole compass of their duty to God and to their neighbor.",
        "In giving them good example.",
        "In bringing them to be confirmed and content.",
      ],
    },
    {
      title: "Three Promises",
      text: [
        "The Bible alone will be your textbook!",
        "Jesus Christ will be uplifted-Our hope in these last days!",
        "You will enjoy studying your Bible and learn more than you may have in your entire life!",
      ],
    },
  ],
};

const questionsAnswers = {
  message:
    "Our church and society need creative and inspiring teachers to guide and develop a new generation of children. Education can do this, if teachers are equipped with new ways of involving children in process of learning. Closing gap, you've members who are teachers or educators by profession but aren't willing to teach children in your church. There is absolutely nothing wrong about that they're there to worship NOT to teach and it's their right. On the other hand, you've people who are willing and passionate about children but aren't competent and qualified. Why don't you consider us? Let us help you train them by giving them tools on how to become an effective teacher.",
  questions: [
    {
      question: "Dear Beginner Teacher,",
      answer:
        "Teaching is an art, simple as that. You can have all the knowledge, know-how & qualifications in the world, but we must understand that teaching is a creative art-firm where your ability to inspire those before you is of paramount importance!",
    },
    {
      question: "Wanna know how I did it?",
      answer:
        "While I was studying at Rhodes University I learned the insider secrets of what is needed to become highly sought after, and Little Ark is going to teach you how to skip the learning curve, build and groom your learners faster, and get to know how to be an effective teacher! We, Little Ark are here to show you insider secrets of how to get to get it right the first time. We will give you the tools, techniques, and strategies to monetize your expertise including: 1. The #1 mistake new teachers make and how you can jump ahead to pro-status fast. 2. How to groom your audience? 3. The proven formula to reach your dream and goal by leveraging your expertise. “The time to act is when the idea is hot and the emotion is strong.” Jim Rohn. I await from you Regards, L Peter Director: Little Ark Ministries",
    },
  ],
};

const booking = {
  text: "To book a training seminar please email ",
  email: "training@littlearkministries.co.za",
};

const topicsCovererd = {
  title: "Some of the topics covered in training",
  topics: [
    {
      title: "The Education of Children",
      text:
        "We look at who is responsible and how? In this we look at the relationship between the teacher, parent and the child. We also look at the cognitive development of the child through learning.",
    },
    {
      title: "Tools for Effective Teaching",
      text:
        "Teaching is multidimensional and in that it involves many different domains. We often think of teaching in terms of academic or cognitive or religious domains. However, teaching also involves social, affective, moral and health domain, as well as many other domains of child's life.",
    },
    {
      title: "Characteristics of an Effective Teacher",
      text:
        "In this we follow Killen (2015) where he speaks of teaching as a matter of 'Expertise rather than Experience.'",
    },
    {
      title: "Managing and Organizing Learning (MOL)",
      text:
        "We aim to develop a lifelong child who is confident, compassionate, while the objective is to give the beginner teacher an idea of the huge responsibility they have to mould these young minds.",
    },
    {
      title: "Language Emphasis",
      text:
        "The value and importance of language are taken for granted. We show that lack of language proficiency limits boundaries. We believe that language underpins ALL education and learning.",
    },
  ],
};

const testimonials = [
  {
    name: "Rev M.A.M Moroke",
    message:
      "As the President of the Children's Ministry in East London Circuit 314 Rev M.A.M Moroke I am so grateful and excited about what Rev Peter did to pur Sunday school Children Practinors during our Development and Empowerment Training it was good and informative indeed. It introduced key aspects of teaching children & the particular characteristics of children. Our Teachers have information and tools now to Empower our young ones. Thank you Sir please continue to empower and Develop others in our Society God bless you.",
  },
  {
    name: "Sisonke Jaba",
    message:
      "In as much as we are teachers; we are parents as well to our own; the training changed both our work and personal life in such a positive way. We acquired skills and tips in various aspects such as; control in a classroom; ways to make the learning fun & memorable. Ps Peter is very experienced with total success from start to finish. The training was Informative Eye - Opening Covering all areas brilliantly. Giving us an opportunity to discuss issues openly. Open; honest without prejudice, Entertaining & FUN I love the fact that is was practical and examples used we could relate to. As the church we have a syllabus formation session because of the insight we received during training. I recommend Little Ark Ministries to all mom's & dads; Sunday school teachers. I will surely be worth your while plus give an opportunity to build a strong and lasting relationship with the children. Thank you.",
  },
  {
    name: "Sivu Mani Ndlebe",
    message:
      "I was approached by church board members in January this year to be the children's ministry director. As clueless as I was I agreed cause you cannot say No to God's work. I heard about “LITTLE ARK MINISTRIES” and how good they are to people who are dealing with kids, be it in schools or churches. I approached them to come and do the church programme for a day and the parents were so taken and blown away. I had to organise a training cause there was a lot that was interesting and a lot that the parent and I (as a CHM director) was not doing right. We were taught on how to deal with different types of children as they all have different and all are different types of learners. Noah's Ark story was one of the best stories that “LITTLE ARK” mastered (assuming their name was insipired by that story as well) ☺, the kids in my class as young as 4 yrs old can tell that story without being reminded or assisted, thats how good the “Little Ark” is. We are looking forward to invite you to the next training so that we can gain some new teaching skills and more interesting stories🙏🙏",
  },
  {
    name: "Ntombi Panyapanya",
    message:
      "Good books for growing readers (the children) and adults too. They offer simple explanations to complex scripture passages. They make life so much easier. Imagine explaining the 7 seals to a toddler? Quite an exercise right? The breakdown on the 7 seals workbook is amazing, as an adult I've too learnt a lot.",
  },
  {
    name: "Dr. M Gwala",
    message:
      "The director of the Little Ark Ministries is very talented in the area of children. I had a privilege of inviting him to serve the children for 4 occasions. The children love him and they all get involved in all the activities he does with them. He has the science of keeping children interested and loving the bible lessons presented at their level. I also had a privilege to invite him to conduct a training for my children’s ministries and teachers, he did an excellent job. He taught teachers many skills in teaching children, entertaining children, instilling and reinforcement of biblical and ethical values. Little Ark Ministries has a lot to offer in our communities. Thank you Nqabane for such an enriching ministry.",
  },
];

export {
  trainingFruits,
  questionsAnswers,
  booking,
  topicsCovererd,
  testimonials,
};
