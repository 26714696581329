import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = sanityClient({
  projectId: "1nfu1qnh",
  dataset: "production",
  apiVersion: "2022-07-07",
  useCdn: true,
  token: process.env.NEXT_PUBLIC_SANITY_TOKEN_TWO,
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
