import React from "react";
import { Link } from "react-router-dom";

const navlinks = [
  {
    id: 0,
    url: "/",
    text: "Home",
    active: false,
  },
  {
    id: 1,
    url: "/about",
    text: "About",
    active: false,
  },
  {
    id: 2,
    url: "/charity",
    text: "Charity",
    active: false,
  },
  {
    id: 3,
    url: "/gallery",
    text: "Gallery",
    active: false,
  },
];

export const NavLinks = () => {
  return navlinks.map((link) => {
    const { id, url, text } = link;
    return (
      <li key={`${id}nav-link`} className="nav-item active">
        <Link className="nav-link" to={url}>
          {text}
        </Link>
      </li>
    );
  });
};

let programLinks = [
  {
    id: 0,
    url: "/children-programs",
    text: "Children Programs",
    active: false,
  },
  {
    id: 1,
    url: "/children-parties",
    text: "Children Parties",
    active: false,
  },
  {
    id: 2,
    url: "/training",
    text: "Training",
    active: false,
  },
  {
    id: 3,
    url: "/Parenting",
    text: "Parenting",
    active: false,
  },
];

export const ProgramLinks = (isOpen) => {
  return programLinks.map((link) => {
    const { id, url, text } = link;
    return (
      <li key={`${id}nav-link-program`}>
        <Link className={isOpen ? "nav-link isOpen" : "nav-link"} to={url}>
          {text}
        </Link>
      </li>
    );
  });
};
