import React from "react";
import { trainingFruits } from "../../staticData/trainingData";

export default function TrainingFruits() {
  let { benefits } = trainingFruits;
  return (
    <>
      <section className="trainingFruit-List">
        <div className="trainingFruitList-center">
          {benefits.map((item, index) => {
            return (
              <div
                key={`training-fruits-index-${index}`}
                className="training-fruit"
              >
                <h4>{item.title}</h4>
                <ul>
                  {item.text.map((innerItem, indexInner) => {
                    return (
                      <li key={`inner-item-index-${indexInner}`}>
                        {innerItem}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}
