import React from "react";
import defaultImg from "../assets/images/logo.png";
import PropTypes from "prop-types";

export default function Product({ product, urlFor }) {
  let { name, slug, image, price } = product;
  slug = slug.current;
  //const storeLink = "https://store.littlearkministries.co.za/product";
  const storeLink = "https://store.littlearkministries.co.za/product";
  //const { image, name, description,slug,keywords,featured, price } = product;

  return (
    <>
      <article className="book">
        <div className="book-img-container">
          <div className="book-image">
            <a
              href={`${storeLink}/${slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={urlFor(image && image[0]) || defaultImg} alt={name} />
            </a>
          </div>

          <div className="book-title-price">
            <a
              href={`${storeLink}/${slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="book-title">{name}</p>
            </a>

            <h5 className="price">
              <span>R</span>
              {price}
            </h5>
          </div>
        </div>
      </article>
    </>
  );
}

Product.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.object.isRequired,
    image: PropTypes.array.isRequired,
    inCart: PropTypes.bool.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
};
