import React from "react";
import YoutubeGallery from "./gallery/youtubeGallery";
import ImageGallery from "./gallery/imageGallery";

function GalleryList() {
  return (
    <>
      <section className="galleryList">
        <ImageGallery />
        <YoutubeGallery />
      </section>
    </>
  );
}
export default GalleryList;
