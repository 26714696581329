import React from "react";
import { questionsAnswers, booking } from "../../staticData/trainingData";

export default function TrainingQuestionsAnswers() {
  let { message, questions } = questionsAnswers;
  const { text, email } = booking;

  return (
    <>
      <section className="trainingQA-List">
        <div className="trainingQAList-center">
          {questions.map((item, index) => {
            return (
              <div
                key={`questions-item-index-${index}`}
                className="question-answer"
              >
                <h4>{item.question}</h4>
                <p>{item.answer}</p>
              </div>
            );
          })}
          <p className="message">{message}</p>
          <div className="booking">
            <p>{text}</p>
            <a href={"mailto:" + email}>{email}</a>
          </div>
        </div>
      </section>
    </>
  );
}
