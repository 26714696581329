import React from "react";
import Title from "../title";
import { parentingSeminars } from "../../staticData/parentingData";

export default function ParentingSeminars() {
  const { message, booking, topicsCovered } = parentingSeminars;
  const { title, topics } = topicsCovered;
  const { email, text } = booking;
  return (
    <>
      <section className="parenting-seminars">
        <div className="parenting-seminars-topics-title">
          <Title title={title} />
          <div className="parenting-seminars-topics">
            {topics.map((item, index) => {
              return (
                <div key={`seminar-topics-index-${index}`} className="topic">
                  <p>{item}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="seminar-booking-message">
          <p className="message">{message}</p>
          <Title title="Parenting Seminar Bookings" />
          <p className="booking">
            {text}
            <a href={"mailto:" + email}>{email}</a>
          </p>
        </div>
      </section>
    </>
  );
}
