import React from "react";
import { RandomWelcomeMessage } from "../../staticData/homeData";

function HomeWelcomeMessage() {
  /**
   * The message object must have the following
   * message ["if you don't have", "you won't"]
   * keywords ["fun", "learn"]
   *  >> keywords must be bold, italic
   */
  return (
    <div className="home-welcome-message">
      <div className="home-welcome-messageContainer">
        <RandomWelcomeMessage />
      </div>
    </div>
  );
}
export default HomeWelcomeMessage;
