import React from "react";
import Title from "../title";
import { parentingQuotes } from "../../staticData/parentingData";

export default function ParentingQuotes() {
  return (
    <>
      <section className="parenting-Quotes">
        <Title title="Some of the quotes we like" />
        <div className="parenting-Quotes-slide">
          {parentingQuotes.map((item, index) => {
            return (
              <div key={`quotes-item-index-${index}`} className="Quote">
                <p>{item.text}</p>
                <p> - {item.author}</p>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}
