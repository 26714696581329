import React, { Component } from "react";
import Hero from "../components/hero";
import Banner from "../components/banner";
import { Link } from "react-router-dom";
import GalleryList from "../components/galleryList";

export default class Gallery extends Component {
  render() {
    return (
      <>
        <Hero hero="defaultPageHero">
          <Banner title="Our Gallery">
            <Link to="/" className="btn btn-primary">
              return Home
          </Link>
          </Banner>
        </Hero>
        <section className="Gallery">
          <GalleryList />
        </section>
      </>
    )
  }
}
